@font-face {
  font-family: 'Crushed';
  src: local('Crushed'), url(../assets/fonts/Crushed/Crushed-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url(../assets/fonts/Quicksand/Quicksand-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Quill Ink';
  src: local('Quill Ink'), url(../assets/fonts/Quill/QuillInk.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: #63666d;
}
/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background:  #393c42;
  box-shadow: inset 4px 4px 12px #13161b;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #63666d;
  border-radius: 12px;
  box-shadow: inset 1px 0px 2px #13161b;
}